<template>
  <div class="home">
    <div class="card-con">
      <div class="container">
        <h1 class="title">开源产品</h1>
        <p class="text-muted" style="text-align:center">
          基于创新的开源模式，向中国数字化团队交付被广泛验证、可信赖的通用工具软件。
        </p>
        <el-row :gutter="30" style="margin-top: 30px">
          <el-col
            :xs="24"
            :sm="24"
            :md="12"
            :lg="8"
            :xl="8"
            v-for="(item, index) in proList"
            :key="index"
            style="margin-bottom: 30px"
          >
            <!-- @mouseenter="enter(item.title)"
              @mouseleave="leave()" -->
            <a :href="item.url" target="_blank">
              <el-card class="card-pro-item" shadow="hover">
                <div class="card-pro-body">
                  <h2 style="font-size: 20px;">{{ item.name }}</h2>
                  <img :src="item.logo" style="height: 40px;" />
                  <p
                    class="text-muted"
                    style="font-size: 14px;
                    margin: 0 0 20px"
                  >
                    {{ item.subTitle }}
                  </p>
                  <img alt="GitHub stars" :src="item.stars" />
                </div>
                <div class="content">
                  {{ item.content }}
                </div>
              </el-card>
            </a>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="card-con bg-light">
      <div class="container">
        <h1 class="title">与客户共成长</h1>
        <p class="text-muted" style="text-align:center">
          始终坚持倾听客户反馈，始终坚持高质量持续迭代，与各行各业的客户共成长。
        </p>
        <el-row :gutter="30" style="margin-top: 30px">
          <el-col
            :xs="24"
            :sm="12"
            :md="12"
            :lg="6"
            :xl="6"
            v-for="(item, index) in cardList"
            :key="index"
          >
            <a :href="item.url" target="_blank">
              <el-card
                class="card-item"
                :body-style="{ padding: '0px' }"
                shadow="hover"
              >
                <span class="card-category"
                  ><i class="el-icon-paperclip"></i>{{ item.category }}</span
                >
                <img :src="item.img" class="image" />
                <div class="content">
                  <span>{{ item.content }}</span>
                </div>
              </el-card>
            </a>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
  data() {
    return {
      proList: [
        {
          name: "AI 智能体",
          title: "MaxKB",
          logo: "https://www.fit2cloud.com/images/logo/banner-nav-MaxKB.png",
          subTitle: "基于大模型的开源知识库问答系统",
          content: `MaxKB 是一款基于大语言模型的知识库问答系统，广泛应用于智能客服、企业内部知识库、学术研究与教育等场景。`,
          stars:
            "https://img.shields.io/github/stars/1Panel-Dev/MaxKB?style=social",
          color: "#0A7BE0",
          url: "https://github.com/1Panel-dev/MaxKB"
        },
        {
          name: "运维审计",
          title: "JumpServer",
          logo:
            "https://www.fit2cloud.com/images/logo/banner-nav-JumpServer.svg",
          subTitle: "广受欢迎的开源堡垒机",
          content:
            "JumpServer 开源堡垒机部署广泛，是符合 4A 规范的运维安全审计系统，是广受企业用户喜爱的堡垒机品牌。",
          stars:
            "https://img.shields.io/github/stars/jumpserver/jumpserver?style=social",
          color: "#2b937c",
          url: "https://www.fit2cloud.com/jumpserver/index.html"
        },
        {
          name: "运维面板",
          title: "JumpServer",
          logo: "https://www.fit2cloud.com/images/logo/banner-nav-1Panel.png",
          subTitle: "现代化、开源的 Linux 面板",
          content:
            "1Panel 是一个现代化、开源的 Linux 服务器运维管理面板，帮助你快速建站，高效管理 Linux 服务器。",
          stars:
            "https://img.shields.io/github/stars/1Panel-dev/1Panel?style=social",
          color: "#2b937c",
          url: "https://1panel.cn/"
        },

        {
          name: "BI 分析",
          title: "DataEase",
          logo: "https://www.fit2cloud.com/images/logo/banner-nav-DataEase.svg",
          subTitle: "人人可用的开源 BI 工具",
          content:
            "DataEase 是开源的数据可视化分析工具，帮助用户快速分析数据并洞察业务趋势，实现业务的改进与优化。",
          stars:
            "https://img.shields.io/github/stars/dataease/dataease?style=social",
          color: "#0A7BE0",
          url: "https://www.fit2cloud.com/dataease/index.html"
        },

        {
          name: "软件测试",
          title: "MeterSphere",
          logo:
            "https://www.fit2cloud.com/images/logo/banner-nav-MeterSphere.svg",
          subTitle: "新一代的开源持续测试工具",
          content:
            "MeterSphere 是新一代的测试管理和接口测试工具，让测试工作更简单、更高效，不再成为持续交付的瓶颈。",
          stars:
            "https://img.shields.io/github/stars/metersphere/metersphere?style=social",
          color: "#783887",
          url: "https://www.fit2cloud.com/metersphere/index.html"
        },
        {
          name: "CMS 建站",
          title: "Halo",
          logo: "https://www.fit2cloud.com/images/logo/banner-nav-Halo.svg",
          subTitle: "强大易用的开源建站工具",
          content:
            "Halo 是强大易用的开源建站工具，配合上丰富的模板与插件，帮助你构建你心中的理想站点。",
          stars:
            "https://img.shields.io/github/stars/halo-dev/halo?style=social",
          color: "#0A7BE0",
          url: "https://halo.run/"
        }

        // {
        //   name: "多云管理",
        //   title: "CloudExplorerLite",
        //   logo:
        //     "https://www.fit2cloud.com/images/logo/banner-nav-CloudExplorerLite.svg",
        //   subTitle: "开源的轻量级云管平台",
        //   content:
        //     "CloudExplorer Lite 提供开箱即用的基本功能，同时提供强大的扩展能力来满足企业的定制化需求。",
        //   stars:
        //     "https://img.shields.io/github/stars/CloudExplorer-Dev/CloudExplorer-Lite?style=social",
        //   color: "#0A7BE0",
        //   url: "https://www.fit2cloud.com/cloudexplorer-lite/index.html"
        // },

        // {
        //   name: "软件开发",
        //   title: "Gitea",
        //   logo: "https://www.fit2cloud.com/images/logo/banner-nav-Gitea.svg",
        //   subTitle: "新一代的代码托管平台",
        //   content:
        //     "Gitea 具备核心代码托管能力和 DevSecOps 延伸能力，为广大软件开发者提供接近 GitHub 的使用体验。",
        //   stars:
        //     "https://img.shields.io/github/stars/go-gitea/gitea?style=social",
        //   color: "#0A7BE0",
        //   url: "https://www.fit2cloud.com/gitea/index.html"
        // }
      ],

      // 鼠标滑入效果
      currentHover: null,
      cardList: [
        {
          id: "1",
          img: "https://www.fit2cloud.com/images/case/solution11.png",
          content: "JumpServer 堡垒机护航顺丰科技超大规模资产安全运维",
          category: "JumpServer",
          url: "https://blog.fit2cloud.com/?p=1147"
        },
        {
          id: "2",
          img: "https://www.fit2cloud.com/images/case/solution12.png",
          content: "小红书的 JumpServer 堡垒机大规模资产跨版本迁移之路",
          category: "JumpServer",
          url: "https://blog.fit2cloud.com/?p=516"
        },
        {
          id: "3",
          img:
            "https://www.fit2cloud.com/images/case/customer-jiangsunongxin.jpg",
          content: "江苏农信：JumpServer 堡垒机助力行业云安全运维",
          category: "JumpServer",
          url: "https://blog.fit2cloud.com/?p=666"
        },
        {
          id: "4",
          img: "https://www.fit2cloud.com/images/case/solution14.jpg",
          content: "博世汽车部件通过 JumpServer 管控大规模资产并实现高并发访问",
          category: "JumpServer",
          url: "https://blog.fit2cloud.com/?p=3080"
        },
        {
          id: "5",
          img: "https://www.fit2cloud.com/images/case/solution10.jpg",
          content:
            "万华化学通过 JumpServer 管理全球化分布式 IT 资产，并且实现与云管平台的联动",
          category: "JumpServer",
          url: "https://blog.fit2cloud.com/?p=3504"
        },
        {
          id: "6",
          img:
            "https://www.fit2cloud.com/images/case/customer-tengxunyouxi.jpg",
          content: "腾讯海外游戏基于 JumpServer 构建游戏安全运营能力",
          category: "JumpServer",
          url: "https://blog.fit2cloud.com/?p=3704"
        },
        {
          id: "7",
          img: "https://www.fit2cloud.com/images/case/customer-huolala.jpg",
          content: "货拉拉通过 JumpServer 纳管大规模云上资产",
          category: "JumpServer",
          url: "https://blog.fit2cloud.com/?p=3716"
        },
        {
          id: "8",
          img:
            "https://www.fit2cloud.com/images/case/customer-ningzhengqihuo.jpg",
          content: "宁证期货通过 JumpServer 有效实现安全运维控制",
          category: "JumpServer",
          url: "https://blog.fit2cloud.com/?p=4808"
        },
        {
          id: "11",
          img:
            "https://www.fit2cloud.com/images/case/customer_nongyinjinke.jpg",
          content: "农银金科：金融科技公司DevOps与持续测试应用实践",
          category: "MeterSphere",
          url:
            "https://blog.fit2cloud.com/?p=071bb008-faa1-4ee7-b7f4-c6b7e51adfc7"
        },
        {
          id: "9",
          img: "https://www.fit2cloud.com/images/case/customer-siweikeji.jpg",
          content: "思为科技：MeterSphere 在微服务架构中的自动化测试应用",
          category: "MeterSphere",
          url: "https://blog.fit2cloud.com/?p=3860"
        },
        {
          id: "10",
          img: "https://www.fit2cloud.com/images/case/customer-zhiyuan.jpg",
          content: "致远互联基于 MeterSphere 构建敏捷测试平台与 DevOps 体系",
          category: "MeterSphere",
          url: "https://blog.fit2cloud.com/?p=5069"
        },

        {
          id: "12",
          img: "https://www.fit2cloud.com/images/case/customer-danxia.jpg",
          content: "DataEase 助力丹霞生物实现数据驱动的企业管理",
          category: "DataEase",
          url:
            "https://blog.fit2cloud.com/?p=cdf2a37f-c11b-46cb-b287-8bc32dabd879"
        },
        {
          id: "13",
          img: "https://www.fit2cloud.com/images/case/solution1.png",
          content: "海通证券：云管理平台统一纳管金融云混合基础设施",
          category: "云管",
          url: "https://blog.fit2cloud.com/?p=574"
        },
        {
          id: "14",
          img: "https://www.fit2cloud.com/images/case/solution2.png",
          content: "上汽乘用车：混合云管理赋能企业 IT 数据化运营",
          category: "云管",
          url: "https://blog.fit2cloud.com/?p=586"
        },
        {
          id: "15",
          img: "https://www.fit2cloud.com/images/case/solution3.png",
          content: "江苏农信：依托云管平台构建行业云统一服务门户",
          category: "云管",
          url: "https://blog.fit2cloud.com/?p=656"
        },
        {
          id: "16",
          img: "https://www.fit2cloud.com/images/case/solution5.png",
          content: "中国一汽红旗智云管理平台获“IDC 中国数字化转型大奖”",
          category: "云管",
          url: "https://blog.fit2cloud.com/?p=918"
        },
        {
          id: "17",
          img: "https://www.fit2cloud.com/images/case/solution6.jpg",
          content: "中国金茂：构建面向地产业务的云管平台",
          category: "云管",
          url: "https://blog.fit2cloud.com/?p=690"
        },
        {
          id: "18",
          img: "https://www.fit2cloud.com/images/case/solution9.png",
          content: "大华股份：基于云管平台打通混合IT资源交付壁垒",
          category: "云管",
          url: "https://blog.fit2cloud.com/?p=1115"
        },
        {
          id: "19",
          img: "https://www.fit2cloud.com/images/case/solution7.png",
          content: "博时基金：云原生架构下的统一云管平台",
          category: "云管",
          url: "https://blog.fit2cloud.com/?p=1371"
        },
        {
          id: "20",
          img: "https://www.fit2cloud.com/images/case/solution8.png",
          content: "广州交通信息：云管平台护航交通信息化系统智能运营",
          category: "云管",
          url: "https://blog.fit2cloud.com/?p=938"
        }
      ]

      // carouselList: [
      //   {
      //     img: "https://www.fit2cloud.com/images/customer/index-zhongxin.png",
      //     text: `“FIT2CLOUD 云管平台解决方案对接了中信银行众多基础设施层与复杂的运维管理层，构建全行统一的云管平台，实现计算、存储、网络等资源供给的服务化，优化了流程，加速了新系统上线速度，在中信银行云架构转型的进程中起到承上启下的作用。”`,
      //   },
      //   {
      //     img: "https://www.fit2cloud.com/images/customer/index-haitong.png",
      //     text: `“结合自身金融云的建设规划，海通证券通过部署 FIT2CLOUD 云管平台，实现了对现有混合基础设施资源的统一管理和自助式资源交付。”`,
      //   },
      //   {
      //     img: "https://www.fit2cloud.com/images/customer/index-shangqi.png",
      //     text: `“上汽乘用车通过部署 FIT2CLOUD 管理平台大幅提升企业内部的 IT 资源交付效率和运行效率，全面强化 IT 项目的生命周期管理能力，实现“数据导向”的创新 IT 运营。”`,
      //   },
      //   {
      //     img: "https://www.fit2cloud.com/images/customer/index-tianhongjijin.png",
      //     text: `“最终，云管平台将会演变成为天弘基金 IT 运维的技术中台，通过聚合运维技术能力的方式，持续降低企业IT运营管理的成本。”`,
      //   },
      //   {
      //     img: "https://www.fit2cloud.com/images/customer/index-zhongguojinmao.png",
      //     text: `“通过云管平台项目建设，中国金茂实现了企业 IT 的云化和服务化转型，实现了安全合规要求下的自服务、资源环境全生命周期管理、资源使用精准计量等目标。”`,
      //   },
      //   {
      //     img: "https://www.fit2cloud.com/images/customer/index-xiaohongshu.png",
      //     text: `“通过采纳 Jumpserver 堡垒机软件订阅服务，我们在几天内就完成了 Jumpserver 堡垒机从 0.3.2 到 1.4.9 版本的升级，将超大规模的资产迁移至新平台。”`,
      //   },
      // ],
      // qualificationList: [
      //   "https://www.fit2cloud.com/images/screenshot/kexinyun.png",
      //   "https://www.fit2cloud.com/images/screenshot/iso27001.png",
      //   "https://www.fit2cloud.com/images/screenshot/iso9001.png",
      //   "https://www.fit2cloud.com/images/screenshot/gaoxinjishu.jpg",
      //   "https://www.fit2cloud.com/images/screenshot/ruanjianchanpin.png",
      //   "https://www.fit2cloud.com/images/screenshot/ruanjianqiye.png",
      //   "https://www.fit2cloud.com/images/screenshot/CMMI.jpg",
      //   "https://www.fit2cloud.com/images/screenshot/LINUX2.jpg",
      //   "https://www.fit2cloud.com/images/screenshot/CNCF.jpg",
      // ],
      // activeQualification: [],
    };
  },
  created() {
    // this.qualificationChange(0);
  },
  methods: {
    hoverBodyStyle(color) {
      return `color: ${color};border-top: 3px solid ${color} !important;`;
    },
    hoverContentStyle(color) {
      return `background: ${color}; color: #ffffff;`;
    },
    enter(index) {
      this.currentHover = index;
    },
    leave() {
      this.currentHover = null;
    }
    // qualificationChange(index) {
    //   let prev = index - 1;
    //   let next = index + 1;
    //   if (index === 0) {
    //     prev = this.qualificationList.length - 1;
    //   } else if (index === this.qualificationList.length - 1) {
    //     next = 0;
    //   }
    //   this.activeQualification = [prev, index, next];
    // },
  }
};
</script>

<style scoped lang="scss">
.home {
  .image {
    width: 100%;
    display: block;
  }
  .text-muted {
    color: #8492a6;
    line-height: 28px;
    font-size: 16px;
    font-weight: 500;
  }
  .bg-light {
    background-color: #f8f9fc !important;
    box-shadow: 0 1px 4px 0 rgba(48, 47, 47, 0.05) inset;
  }
  .bg-carousel {
    background: url(https://www.fit2cloud.com/images/banner/banner5.png);
    min-height: 450px;
    background-size: cover;
    background-position: center center;
    position: relative;
    padding: 0 !important;
  }
  .bg-overlay {
    background-color: rgba(60, 72, 88, 0.7);
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
  .card-con {
    padding: 35px 0;
    .title {
      text-align: center;

      margin-bottom: 35px;
    }
    .card-pro-item {
      cursor: pointer;
      ::v-deep .el-card__body {
        padding: 0 !important;
      }

      .card-pro-body {
        text-align: center;
        padding: 15px 0 20px;
      }
      .content {
        background-color: #f8f9fc !important;
        padding: 30px;
        line-height: 25px;
        font-size: 16px;
        color: #3c4858;
      }
    }
    .card-item {
      position: relative;
      margin-bottom: 30px;
      height: 255px;
      cursor: pointer;
      &:hover {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
        .card-category {
          opacity: 1;
        }
      }
      .content {
        padding: 25px;
        line-height: 25px;
        font-size: 15px;
        color: #000000;
        span {
          height: 50px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2; //行数
          -webkit-box-orient: vertical;
        }
      }
      .card-category {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        -webkit-transition: all 0.5s ease;
        transition: all 0.5s ease;
        color: #ffffff;
        font-weight: bold;
        padding: 5px 10px;
        background: rgba(50, 100, 239, 0.6);
      }
    }
  }
  .carousel-style {
    margin-top: 130px;
    color: #ffffff;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
  }
  .qualification-style {
    text-align: center;
  }
}
</style>
